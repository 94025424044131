.skeleton-grid [data-skeleton],
.skeleton {
  background-color: #0000000d;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  z-index: 0;
  color: transparent !important;
  box-shadow: none !important;

  * {
    color: transparent !important;
    border: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
    visibility: hidden;
  }

  &::after {
    animation: skeleton 1.2s ease-out infinite;
    background-image: linear-gradient(90deg, #0000, #0000000d);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@keyframes skeleton {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
