div {
  box-sizing: border-box;
}

p {
  line-height: 1.5;
}

.mat-drawer-content {
  overflow-x: hidden;
}

.action-item-text {
  margin-left: 0.7rem;
}

.dragging {
  cursor: grabbing;
  overflow: hidden;
}

.input-without-padding {
  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0;
    }
  }
}

.bgWhite {
  background-color: #fff;
}

.timepicker-backdrop-overlay,
.timepicker-overlay {
  z-index: 10000 !important;
}
