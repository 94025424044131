.session-wrapper {
  position: relative;

  .mat-tab-group {
    .mat-tab-header {
      background-color: rgb(244 244 244);
    }

    .mat-tab-body-wrapper {
      .mat-tab-body {
        background-color: rgb(244 244 244);
      }
    }
  }
}
