// Стили для сетки фильтров таблицы

.display-flex {
  display: flex;
  flex-basis: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-5 {
  flex: 5;
}

.flex-field {
  padding: 0;
  max-width: 16rem;

  &-18 {
    padding: 0;
    max-width: 18rem;
  }
}

.flex-textarea {
  min-width: 24rem;

  // Выравниваем в две строки фильтра
  textarea {
    height: 6.4rem !important;
  }

  &.flex-field {
    max-width: initial;
  }
}

.flex-column {
  .display-flex {
    flex-wrap: wrap;
    display: inline-flex;
    gap: 0.5rem;
  }

  .flex-field {
    padding: 0;
  }
}

[class^='flex-'] {
  padding-left: 8px;
  padding-right: 8px;
}

[class*='flex-']:first-child {
  padding-left: 0;
}

[class*='flex-']:last-child {
  padding-right: 0;
}

.flex-p-0 {
  padding: 0;
}
