.loading-bg,
html button.mdc-button.loading-bg {
  animation: loading-bg-animation 0.5s linear infinite;
  background-clip: padding-box;
  color: #fff;
  background-image: repeating-linear-gradient(
    -45deg,
    var(--color-primary-600),
    var(--color-primary-600) 4px,
    var(--color-primary) 4px,
    var(--color-primary) 8px
  );
  pointer-events: none;
  background-size: 150%;
}

@keyframes loading-bg-animation {
  0% {
    background-position: -12px 0;
  }

  100% {
    background-position: 0 0;
  }
}
