.goto {
  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field-infix {
      border: 0;
      padding: 0.5rem 0;
    }
  }
}
