.menu-item {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  mat-icon {
    height: 12px;
    width: 20px;
    font-size: 14px;
  }
}
