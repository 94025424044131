/*
    Sidenav
   */

$padding-left__sidenav-item: 36px;
$padding-right__sidenav-item: 24px;
$layout-beta__background__sidenav: white;

/*
    Sidenav: Layout Beta
   */

$layout-beta__color__sidenav-item: #555;
$layout-beta__color__sidenav-heading: rgb(0 0 0 / 30%);
$layout-beta__background__sidenav-item__icon: rgb(0 0 0 / 5%);
$layout-beta__background-color__sidenav-item: $layout-beta__background__sidenav;
$layout-beta__background-color__sidenav-item__hover: darken($layout-beta__background-color__sidenav-item, 2);
$layout-beta__background-color__sidenav-item__active: $layout-beta__background-color__sidenav-item__hover;
$layout-beta__background-color__sidenav-item__level1: darken(
  $layout-beta__background-color__sidenav-item,
  2.5
);
$layout-beta__background-color__sidenav-item__level1__hover: darken(
  $layout-beta__background-color__sidenav-item__level1,
  1.5
);
$layout-beta__background-color__sidenav-item__level1__active: $layout-beta__background-color__sidenav-item__level1__hover;
$layout-beta__background-color__sidenav-item__level2: darken(
  $layout-beta__background-color__sidenav-item__level1,
  2.5
);
$layout-beta__background-color__sidenav-item__level2__hover: darken(
  $layout-beta__background-color__sidenav-item__level2,
  1.5
);
$layout-beta__background-color__sidenav-item__level2__active: $layout-beta__background-color__sidenav-item__level2__hover;
$layout-beta__background-color__sidenav-item__level3: darken(
  $layout-beta__background-color__sidenav-item__level2,
  2.5
);
$layout-beta__background-color__sidenav-item__level3__hover: darken(
  $layout-beta__background-color__sidenav-item__level3,
  1.5
);
$layout-beta__background-color__sidenav-item__level3__active: $layout-beta__background-color__sidenav-item__level3__hover;
$layout-beta__background-color__sidenav-item__level4: darken(
  $layout-beta__background-color__sidenav-item__level3,
  2.5
);
$layout-beta__background-color__sidenav-item__level4__hover: darken(
  $layout-beta__background-color__sidenav-item__level4,
  1.5
);
$layout-beta__background-color__sidenav-item__level4__active: $layout-beta__background-color__sidenav-item__level4__hover;
$layout-beta__background-color__sidenav-item__level5: darken(
  $layout-beta__background-color__sidenav-item__level4,
  2.5
);
$layout-beta__background-color__sidenav-item__level5__hover: darken(
  $layout-beta__background-color__sidenav-item__level5,
  1.5
);
$layout-beta__background-color__sidenav-item__level5__active: $layout-beta__background-color__sidenav-item__level5__hover;
$layout-beta__background-color__sidenav-item__level6: darken(
  $layout-beta__background-color__sidenav-item__level5,
  2.5
);
$layout-beta__background-color__sidenav-item__level6__hover: darken(
  $layout-beta__background-color__sidenav-item__level6,
  1.5
);
$layout-beta__background-color__sidenav-item__level6__active: $layout-beta__background-color__sidenav-item__level6__hover;

/*
    Sidenav-Item: Padding
   */

$padding__sidenav-item__level1: $padding-left__sidenav-item + 10px;
$padding__sidenav-item__level2: $padding-left__sidenav-item + 20px;
$padding__sidenav-item__level3: $padding-left__sidenav-item + 30px;
$padding__sidenav-item__level4: $padding-left__sidenav-item + 40px;
$padding__sidenav-item__level5: $padding-left__sidenav-item + 50px;
$padding__sidenav-item__level6: $padding-left__sidenav-item + 50px;

/*
    Horizontal Navigation
   */

$padding-left__dropdown-item: 24px;
$padding-right__dropdown-item: 18px;
$padding-top-bottom__dropdown-item: 10px;
$padding__dropdown-item: $padding-top-bottom__dropdown-item $padding-right__dropdown-item
  $padding-top-bottom__dropdown-item $padding-left__dropdown-item;
$background__dropdown-item__hover: #f5f5f5;
$background__dropdown-item__icon: rgb(0 0 0 / 5%);
$color__navigation-item: #333;

/*
    Sidenav-Item: Padding
   */

$padding__dropdown-item__level1: $padding-left__dropdown-item + 10px;
$padding__dropdown-item__level2: $padding-left__dropdown-item + 20px;
$padding__dropdown-item__level3: $padding-left__dropdown-item + 30px;
$padding__dropdown-item__level4: $padding-left__dropdown-item + 40px;
$padding__dropdown-item__level5: $padding-left__dropdown-item + 50px;
$padding__dropdown-item__level6: $padding-left__dropdown-item + 50px;

.layout {
  /*
  Sidenav
 */

  .dropdown-item {
    color: $layout-beta__color__sidenav-item;

    &:hover {
      color: #000;
    }

    &.active {
      color: #000;
    }

    .icon,
    .letter-icon {
      background: $layout-beta__background__sidenav-item__icon;
    }
  }

  .dropdown-item-container {
    > .dropdown-item {
      &:hover {
        background: $layout-beta__background-color__sidenav-item__hover;
      }

      &.active {
        background: $layout-beta__background-color__sidenav-item__active;
      }
    }

    > .sub-menu {
      // Level 1 aka 1st Dropdown
      background: $layout-beta__background-color__sidenav-item__level1;

      > .dropdown-item-container {
        > .dropdown-item {
          padding-left: $padding__dropdown-item__level1;

          &:hover {
            background: $layout-beta__background-color__sidenav-item__level1__hover;
          }

          &.active {
            background: $layout-beta__background-color__sidenav-item__level1__active;
          }
        }

        > .sub-menu {
          // Level 2
          background: $layout-beta__background-color__sidenav-item__level2;

          > .dropdown-item-container {
            > .dropdown-item {
              padding-left: $padding__dropdown-item__level2;

              &:hover {
                background: $layout-beta__background-color__sidenav-item__level2__hover;
              }

              &.active {
                background: $layout-beta__background-color__sidenav-item__level2__active;
              }
            }

            > .sub-menu {
              // Level 3
              background: $layout-beta__background-color__sidenav-item__level3;

              > .dropdown-item-container {
                > .dropdown-item {
                  padding-left: $padding__dropdown-item__level3;

                  &:hover {
                    background: $layout-beta__background-color__sidenav-item__level3__hover;
                  }

                  &.active {
                    background: $layout-beta__background-color__sidenav-item__level3__active;
                  }
                }

                > .sub-menu {
                  // Level 4
                  background: $layout-beta__background-color__sidenav-item__level4;

                  > .dropdown-item-container {
                    > .dropdown-item {
                      padding-left: $padding__dropdown-item__level4;

                      &:hover {
                        background: $layout-beta__background-color__sidenav-item__level4__hover;
                      }

                      &.active {
                        background: $layout-beta__background-color__sidenav-item__level4__active;
                      }
                    }

                    > .sub-menu {
                      // Level 5
                      background: $layout-beta__background-color__sidenav-item__level5;

                      > .dropdown-item-container {
                        > .dropdown-item {
                          padding-left: $padding__dropdown-item__level5;

                          &:hover {
                            background: $layout-beta__background-color__sidenav-item__level5__hover;
                          }

                          &.active {
                            background: $layout-beta__background-color__sidenav-item__level5__active;
                          }
                        }

                        .sub-menu {
                          // Infinite Levels after
                          background: $layout-beta__background-color__sidenav-item__level6;

                          .dropdown-item-container > .dropdown-item {
                            padding-left: $padding__dropdown-item__level6;

                            &:hover {
                              background: $layout-beta__background-color__sidenav-item__level6__hover;
                            }

                            &.active {
                              background: $layout-beta__background-color__sidenav-item__level6__active;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .dropdown-item-container .sub-menu {
    position: relative;
    padding: 0;
    overflow: hidden;
  }

  /*
  Sidenav
   */

  .sidenav {
    background: $layout-beta__background__sidenav;

    .menu {
      .heading {
        color: $layout-beta__color__sidenav-heading;
      }
    }
  }
}
