.mt-4 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.mat-padding {
  padding: 0.5rem;
}

.mat-padding-x1 {
  padding: 0.5rem;
}

.mat-padding-x2 {
  padding: 1rem;
}

.mat-padding-x3 {
  padding: 1.5rem;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.form-field-full-width {
  width: 100%;
}

.warn {
  background: #ffa39e;
  padding: 1rem;
  width: 100%;
  margin-bottom: 0.3rem;
  border-radius: 5px;
  text-align: center;
  color: #444;
}
