.dashboard {
  .dashboard-tabs {
    height: 100%;

    > .mat-tab-header {
      background: white;
      border-bottom: none;
      z-index: 10;

      .mat-tab-labels {
        .mat-tab-label {
          font-weight: 400;
          color: #737373;
          opacity: 1;

          &.mat-tab-label-active {
            font-weight: 500;
            color: #000;
          }
        }
      }
    }

    .mat-tab-body-wrapper {
      height: 100%;

      .mat-tab-body {
        height: 100%;

        .mat-tab-body-content {
          overflow-x: hidden;
        }
      }
    }
  }
}
