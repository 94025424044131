// Исправляет в macOs отображение скрола

::-webkit-scrollbar {
  appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(0 0 0 / 20%);
  box-shadow: 0 0 1px rgb(255 255 255 / 20%);
}

/*
 *  STYLE 7
 */
.small-scrollbar::-webkit-scrollbar-track {
  padding: 8px;

  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  //background-color: #F5F5F5;
  //border-radius: 10px;
  background: none;
}

.small-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;

  //background-color: #F5F5F5;
  //display: none;
}

.small-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(217 217 217)),
    color-stop(0.72, rgb(189 189 189)),
    color-stop(0.86, rgb(148 148 148))
  );
}
