.custom-accordion {
  .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    background-color: #f4f4f4;
  }

  .mat-expansion-panel-header {
    padding: 0 39px;
    height: 88px;
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background-color: #f4f4f4;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
    box-shadow: none;
  }

  .mat-expansion-panel-body {
    padding: 0;
    background-color: white;
  }

  .mat-expansion-panel {
    box-shadow: 0 0 1px 1px #dadada;
  }
}
