// Портирование стилей устаревшего пакета @angular/flex-layout

html {
  .fx-layout-row {
    flex-direction: row;
    display: flex;
  }

  body .fx-layout-row-1280 {
    @media (width >= 1280px) {
      flex-direction: row;
      display: flex;
    }
  }

  body .fx-layout-row-1440 {
    @media (width >= 1440px) {
      flex-direction: row;
      display: flex;
    }
  }

  .fx-layout-column {
    flex-direction: column;
    display: flex;
  }

  body .fx-layout-column-1440 {
    @media (width >= 1440px) {
      flex-direction: column;
      display: flex;
    }
  }
}

.fx-layout-wrap {
  flex-wrap: wrap;
}

.fx-layout-nowrap {
  flex-wrap: nowrap;
}

// x

.fx-layout-align-x-center {
  justify-content: center;
}

.fx-layout-align-x-start {
  justify-content: flex-start;
}

.fx-layout-align-x-end {
  justify-content: flex-end;
}

.fx-layout-align-x-space-between {
  justify-content: space-between;
}

.fx-layout-align-x-space-around {
  justify-content: space-around;
}

// y

.fx-layout-align-y-center {
  align-items: center;
  align-content: center;
}

.fx-layout-align-y-start {
  align-items: flex-start;
  align-content: flex-start;
}

html body .fx-layout-align-y-start-1440 {
  @media (width >= 1440px) {
    align-items: flex-start;
    align-content: flex-start;
  }
}

.fx-layout-align-y-end {
  align-items: flex-end;
  align-content: flex-end;
}

.fx-layout-align-column-y-stretch {
  align-content: stretch;
  align-items: stretch;
  max-height: 100%;
}

.fx-layout-align-row-y-stretch {
  align-content: stretch;
  align-items: stretch;
  max-width: 100%;
}

.fx-layout-align-row-y-baseline {
  align-items: baseline;
  align-content: stretch;
}

// gap
.fx-layout-gap-0-5rem {
  gap: 0.5rem;
}

.fx-layout-gap-1rem {
  gap: 1rem;
}

.fx-layout-gap-2rem {
  gap: 2rem;
}

.fx-layout-gap-3rem {
  gap: 3rem;
}

// fill
.fx-layout-fill {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

// width

@mixin fx-layout-width($width) {
  flex: 1 1 $width;
  min-width: $width;
  max-width: $width;
}

.fx-layout-w-10 {
  @include fx-layout-width(10%);
}

.fx-layout-w-15 {
  @include fx-layout-width(15%);
}

.fx-layout-w-20 {
  @include fx-layout-width(20%);
}

.fx-layout-w-24 {
  @include fx-layout-width(24%);
}

.fx-layout-w-25 {
  @include fx-layout-width(25%);
}

.fx-layout-w-29 {
  @include fx-layout-width(29%);
}

.fx-layout-w-30 {
  @include fx-layout-width(30%);
}

.fx-layout-w-35 {
  @include fx-layout-width(35%);
}

.fx-layout-w-45 {
  @include fx-layout-width(45%);
}

.fx-layout-w-47 {
  @include fx-layout-width(47%);
}

.fx-layout-w-48 {
  @include fx-layout-width(48%);
}

.fx-layout-w-49 {
  @include fx-layout-width(49%);
}

.fx-layout-w-50 {
  @include fx-layout-width(50%);
}

.fx-layout-w-70 {
  @include fx-layout-width(70%);
}

.fx-layout-w-75 {
  @include fx-layout-width(75%);
}

.fx-layout-w-80 {
  @include fx-layout-width(80%);
}

.fx-layout-w-85 {
  @include fx-layout-width(85%);
}

.fx-layout-w-95 {
  @include fx-layout-width(95%);
}

.fx-layout-w-100 {
  @include fx-layout-width(100%);
}

html body .fx-layout-w-100-1440 {
  @media (width >= 1440px) {
    @include fx-layout-width(100%);
  }
}

.fx-layout-w-auto {
  flex: 1 1 auto;
}

.fx-layout-w-1px {
  @include fx-layout-width(1px);
}

.fx-layout-w-5px {
  @include fx-layout-width(5px);
}

.fx-layout-w-10px {
  @include fx-layout-width(10px);
}

.fx-layout-w-30px {
  @include fx-layout-width(30px);
}

.fx-layout-w-40px {
  @include fx-layout-width(40px);
}

.fx-layout-w-50px {
  @include fx-layout-width(50px);
}

.fx-layout-w-60px {
  @include fx-layout-width(60px);
}

.fx-layout-w-100px {
  @include fx-layout-width(100px);
}

.fx-layout-w-150px {
  @include fx-layout-width(150px);
}

.fx-layout-w-200px {
  @include fx-layout-width(200px);
}

.fx-layout-w-230px {
  @include fx-layout-width(230px);
}

.fx-layout-w-240px {
  @include fx-layout-width(240px);
}

.fx-layout-w-250px {
  @include fx-layout-width(250px);
}

.fx-layout-w-260px {
  @include fx-layout-width(260px);
}

.fx-layout-w-350px {
  @include fx-layout-width(350px);
}

.fx-layout-w-360px {
  @include fx-layout-width(360px);
}

.fx-layout-w-370px {
  @include fx-layout-width(370px);
}

.fx-layout-w-450px {
  @include fx-layout-width(450px);
}

.fx-layout-w-470px {
  @include fx-layout-width(470px);
}

.fx-layout-w-500px {
  @include fx-layout-width(500px);
}

html body .fx-layout-w-500px-1440 {
  @media (width >= 1440px) {
    @include fx-layout-width(500px);
  }
}

.fx-layout-w-600px {
  @include fx-layout-width(600px);
}

.fx-layout-w-700px {
  @include fx-layout-width(700px);
}

.fx-layout-w-550px {
  @include fx-layout-width(550px);
}

.fx-layout-w-1rem {
  @include fx-layout-width(1rem);
}

.fx-layout-w-2rem {
  @include fx-layout-width(2rem);
}

.fx-layout-w-4rem {
  @include fx-layout-width(4rem);
}

.fx-layout-w-5rem {
  @include fx-layout-width(5rem);
}

.fx-layout-w-6rem {
  @include fx-layout-width(6rem);
}

.fx-layout-w-7rem {
  @include fx-layout-width(7rem);
}

.fx-layout-w-8rem {
  @include fx-layout-width(8rem);
}

.fx-layout-w-10rem {
  @include fx-layout-width(10rem);
}

.fx-layout-w-12rem {
  @include fx-layout-width(12rem);
}

.fx-layout-w-15rem {
  @include fx-layout-width(15rem);
}

.fx-layout-w-20rem {
  @include fx-layout-width(20rem);
}

.fx-layout-w-25rem {
  @include fx-layout-width(25rem);
}

.fx-layout-w-30rem {
  @include fx-layout-width(30rem);
}

.fx-layout-w-44rem {
  @include fx-layout-width(44rem);
}

.fx-mt-16 {
  margin-top: 4rem;
}

.fx-mb-32 {
  margin-bottom: 8rem;
}

.fx-mb-4 {
  margin-bottom: 1rem;
}

.fx-mb-6 {
  margin-bottom: 1.5rem;
}

.fx-mb-8 {
  margin-bottom: 2rem;
}

.fx-ml-4 {
  margin-left: 1rem;
}

.fx-mt-4 {
  margin-top: 1rem;
}

.fx-mr-4 {
  margin-right: 1rem;
}

.fx-mr-2 {
  margin-right: 0.5rem;
}
