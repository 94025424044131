// Сетка для фильтров
html .page__actions {
  &.grid-12 {
    display: grid;
    grid-template: auto / repeat(12, 1fr);
  }
}

.grid-span-12-end {
  grid-column: span 12;
  justify-self: end;
}

.grid-span-11 {
  grid-column: span 11;
}

.table {
  .mat-sort-header-container {
    display: inline-flex !important;
  }
}

// Columns

.tariffs-cell {
  &__block {
    padding: 0.2rem 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border: none;
    }
  }

  &__title {
    b {
      display: inline-block;
      padding-right: 0.3rem;
    }
  }

  &__tariff {
    color: #666;
  }
}
