@import 'https://api.mobility.hyundai.ru/public/fonts/fleet.1.2/style.css?ver=1.2';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  font-size: 12px;
  margin: 0;
  color: #111;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* You can add global styles to this file, and also import other styles files */
@import 'styles/utils';
@import 'styles/contextmenu';
@import 'styles/table-filter-layout';
@import 'styles/misc';
@import 'styles/tabs';
@import 'styles/badges';
@import 'styles/components/goto';
@import 'styles/components/custom-accordion';
@import 'styles/components/dashboard';
@import 'styles/components/ng-selector';
@import 'styles/layouts/layout-gamma';
@import 'styles/components/nz/form-label';
@import 'styles/loading';
@import 'styles/components/table';
@import 'styles/mat-icon';
@import 'styles/scrollbar';
@import 'styles/tooltip';
@import 'styles/json-form';
@import 'styles/quill';
@import 'styles/components';
@import 'styles/skeleton';
