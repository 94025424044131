.badge {
  padding: 3px 8px;
  border-radius: 12px;
  line-height: normal;

  &.primary {
    background: var(--color-primary);
    color: #444;
  }

  &.accent {
    background: var(--color-accent);
    color: #444;
  }

  &.warn {
    background: var(--color-warn);
    color: #444;
  }
}
