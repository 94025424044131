.excel-icon {
  background: url('/assets/icons/files/excel.svg');
  background-size: 95%;
  background-position: center;
  background-repeat: no-repeat;
}

.pdf-icon {
  background: url('/assets/icons/files/file-pdf-o.svg');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
