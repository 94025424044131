.ng-select.ng-select-disabled .ng-select-container::after {
  background-size: 4px 2px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #1976d2;
  border-radius: 0.375rem;
  line-height: 24px;
  height: 30px;

  .ng-value-label {
    font-size: 0.75rem;
  }

  .ng-placeholder {
    font-weight: 400;
  }
}

.ng-select.ng-select-focused .ng-select-contain#f5f5f5er::after {
  border-bottom-color: #1976d2;
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  color: #1976d2;
}

.filter.ng-select .ng-select-container.ng-appearance-outline .ng-placeholder {
  background-color: #f5f5f5;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  z-index: 1;
}
