quill-editor .ql-editor {
  min-height: 60vh;
  max-height: 76vh;
}

.ql-container {
  // Переопределяем шрифты
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;

  // Убираем переносы
  .ql-editor {
    white-space: normal;
  }
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'Ссылка:';
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: 'Сохранить';
}
