// Глобально переопределяем стили компонентов темы материала

// Expansion panel
// Задаёт высоту для раскрывающихся панелей
.mat-expansion-panel {
  .mat-expansion-panel-header {
    height: 44px;
    font-size: inherit;
  }

  .mat-expansion-panel-content {
    font: inherit;
  }
}

// Возвращаем родной датапикер для инпута
::-webkit-calendar-picker-indicator {
  display: block !important;
  scale: 1.3;
}

// Используется для хеадера раскрывающихся панелей, чтобы не обрезался бейдж
.mat-content {
  overflow: visible !important;
}

html {
  body {
    font-family:
      Inter,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
  }

  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  legend {
    margin-bottom: 1.5rem;
  }

  // Input
  // Шрифт маленький и получается отступ снизу, поэтому делаем по центру
  .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
  }

  // Уменьшаем подсказку для поля ввода
  .mat-mdc-form-field-hint {
    color: #666;
  }

  .mat-mdc-form-field-hint-wrapper {
    padding: 0 4px;
  }

  // Делаем по центру ошибку после поля ввода
  .mat-mdc-form-field-error,
  .mat-mdc-form-field-hint {
    font-size: 10px;
    display: flex;
    align-items: flex-start;
    line-height: 1;
    position: relative;
    left: 0;
    top: 0;
  }

  // Исправляем единицу измерения для инпута
  .mat-mdc-form-field-icon-suffix {
    padding: 0 4px;
  }

  // Table
  .mat-mdc-table .mat-mdc-header-cell {
    white-space: nowrap;
  }

  // Snapbar
  .mdc-snackbar__surface {
    padding-right: 0;
  }

  .none {
    display: none;
  }

  .warnMessage.mat-mdc-snack-bar-container {
    white-space: pre-line;

    .mdc-snackbar__surface {
      background-color: #fbe9e7;
    }

    .mdc-snackbar__label {
      color: #e64a19;
    }

    .mdc-button__label {
      color: #e64a19;
    }

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      color: #e64a19;
    }
  }

  .okMessage.mat-mdc-snack-bar-container {
    white-space: pre-line;

    .mdc-snackbar__surface {
      background-color: #e8f5e9;
    }

    .mdc-snackbar__label {
      color: #388e3c;
    }

    .mdc-button__label {
      color: #388e3c;
    }

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      color: #388e3c;
    }
  }

  .updateMessage.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      background: #fff;
    }

    .mdc-snackbar__label {
      color: var(--color-primary);
    }
  }

  // Dialog
  .mat-mdc-dialog-container .mdc-dialog__title {
    font-size: 1.1rem;
  }

  .mdc-dialog__title::before {
    height: 2.6rem;
  }

  .mdc-dialog__title + .mdc-dialog__content {
    padding: 5px 24px 0;
  }

  .mat-mdc-dialog-content {
    max-height: 80vh;
  }

  // Font
  &,
  .mat-tree {
    font-family:
      Inter,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
  }

  // Icon
  // Цвет для иконок по умолчанию
  .mat-mdc-menu-item .mat-icon-no-color,
  .mat-mdc-menu-submenu-icon {
    color: rgb(0 0 0 / 54%);
  }

  // Дисаблед текст делаем ярче
  .mdc-text-field--disabled .mdc-text-field__input {
    color: rgb(0 0 0 / 87%);
  }

  // Button

  // Делает текст по центру
  .mdc-button {
    line-height: 1;
  }

  // Увеличиваем иконку в кнопке
  .mat-mdc-unelevated-button > .mat-icon,
  .mat-mdc-button > .mat-icon,
  .mat-mdc-raised-button > .mat-icon,
  .mat-mdc-outlined-button > .mat-icon {
    font-size: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  // Card
  .mat-mdc-card-title {
    line-height: 1.5;
  }

  .mat-mdc-card-title ~ .mat-mdc-card-subtitle {
    line-height: 1.5;
  }

  // Отступ от шапки карточки
  .mat-mdc-card-header-text {
    margin-bottom: 1.325rem;
    width: 100%;
  }

  // Добавляем отступ для карточки
  .mat-mdc-card {
    padding: 1.325rem;
  }

  .mat-mdc-card-content:last-child {
    padding: 0;
  }

  .mat-mdc-card-content:first-child {
    padding: 0;
  }

  .mat-mdc-card-header {
    padding: 0;
  }

  .mat-mdc-card-content {
    padding: 0;
  }

  .mdc-card__actions {
    padding: 8px 0;
  }

  // List
  // Делаем высоту динамической
  .mdc-list-item.mdc-list-item--with-one-line {
    height: auto;
    min-height: 48px;
  }

  // Formly
  formly-field-mat-input,
  formly-field-mat-textarea {
    width: 100%;
  }

  // Tab
  // Включаем поддержку событий внутри вкладки таба
  .mdc-tab__content {
    pointer-events: all;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

// Локальные переопределения
.mat-theme-overwrite {
  // Hint
  &__hint-white {
    .mat-mdc-form-field-hint {
      color: #fff;
    }
  }

  // Input Error
  &__input-error-panel {
    .mat-mdc-form-field-error {
      color: #fff;
      background-color: var(--color-red);
      padding: 0.2rem 0.5rem;
      line-height: 1;
      font-weight: 500;
      border-radius: 3px;
      text-align: center;
      font-size: 0.825rem;
    }

    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }
  }

  // List
  &__line-clamp-2 {
    .mat-list-text {
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  // Button

  &__button-small .mat-mdc-button-base {
    height: 2rem;
  }

  &__button-icon-small {
    .mdc-icon-button {
      font-size: 4px;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 28px;
      height: 28px;
      line-height: 0.1;
      padding: 0;
    }

    .mat-icon {
      height: 18px;
      width: 18px;
    }

    .material-icons {
      font-size: 18px;
    }
  }

  &__button-icon-nano {
    .mdc-icon-button {
      font-size: 0;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 20px;
      height: 20px;
      padding: 0;
    }

    .mat-icon {
      height: 14px;
      width: 14px;
    }

    .material-icons {
      font-size: 14px;
    }
  }

  &__icon-16 {
    .mat-icon {
      height: 1.4rem;
      width: 1.4rem;
    }

    .material-icons {
      font-size: 1.4rem;
    }
  }

  // Input
  // Простой маленький input
  &__input-simple {
    .mat-mdc-form-field-infix {
      min-height: 15px;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  &__input-full-width {
    .mat-mdc-form-field {
      width: 100%;
    }

    // Исправляем ширину селекта
    formly-field-mat-select {
      width: 100%;
    }
  }

  &__input-full-min-width {
    .mat-mdc-form-field-infix {
      width: 100%;
    }
  }

  // Убираем низ у поля ввода
  &__input-without-subscript {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  // Menu
  &__menu-item-compact {
    .mat-mdc-menu-item {
      min-height: 3rem;
    }
  }

  // Checkboxes
  // Компактное расположение чекбоксов
  &__checkbox-compact {
    .mat-mdc-checkbox .mdc-checkbox {
      padding: 2px;
    }

    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
      top: 2px;
      left: 2px;
    }
  }

  &__card-default {
    .mat-mdc-card {
      padding: 0;
    }

    .mat-mdc-card-header {
      padding: 16px 16px 0;
    }

    .mat-mdc-card-content {
      padding: 0 16px;
    }

    .mat-mdc-card-content:first-child {
      padding: 16px 16px 0;
    }
  }

  // List
  &__list-compact {
    .mdc-list-item.mdc-list-item--with-one-line {
      height: auto;
      min-height: 32px;
    }
  }

  &__smart-filter {
    .mdc-evolution-chip-set {
      width: 100%;
    }
  }
}

// Browser fixes
.safari-browser {
  // Убираем дефолтную текущую дату по умолчанию, когда нет значения

  // Скрываем компонент дата-пикера
  .empty-datetime-local::-webkit-datetime-edit {
    display: none;
  }

  .empty-datetime-local:focus:not(:disabled, :read-only)::-webkit-datetime-edit {
    display: initial;
  }

  // Добавляем плейсхолдер
  .empty-datetime-local::after {
    content: 'дд.мм.гггг, --:--';
  }

  .empty-datetime-local:not(:disabled, :read-only):focus::after {
    content: '';
  }
}
