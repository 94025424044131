// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-primary: mat.define-palette(mat.$blue-palette, 700, A100, 800, 400);
$angular-accent: mat.define-palette(mat.$orange-palette, 200, 100, A200);

// The warn palette is optional (defaults to red).
$angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: Inter,
        $headline-1: mat.define-typography-level(24px, 2, 500, Inter),
        $headline-2: mat.define-typography-level(24px, 2, 500, Inter),
        $headline-3: mat.define-typography-level(18px, 1, 500, Inter),
        $headline-4: mat.define-typography-level(18px, 1, 500, Inter),
        $headline-5: mat.define-typography-level(14px, 2, 500, Inter),
        $headline-6: mat.define-typography-level(12px, 1, 500, Inter),
        $subtitle-1: mat.define-typography-level(12px, 1, 500, Inter),
        $subtitle-2: mat.define-typography-level(12px, 1, 500, Inter),
        $body-1: mat.define-typography-level(12px, 1.2, 400, Inter),
        $body-2: mat.define-typography-level(12px, 1.2, 400, Inter),
        $caption: mat.define-typography-level(12px, 1, 400, Inter),
        $button: mat.define-typography-level(12px, 1, 400, Inter),
      ),
    density: -2,
  )
);
$button-theme: mat.define-light-theme(
  (
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-theme);
@include mat.button-theme($button-theme);

:root {
  --color-primary: #1976d2;
  --color-primary-600: #1e87e5;
  --color-accent: #ffcc80;
  --color-error: #f44336;
  --color-warn: #fb8c00;
  --color-red: #e53935;
  --color-green: #43a047;
  --color-yellow: #fdd835;
  --color-cyan: #00838f;
  --color-grey-light: #f4f4f4;
  --color-brand-dark: #00aad2;
  --color-blue-dark: #2c6fe1;
}
